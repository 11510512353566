import styles from "../styles/Footer.module.scss";

export default function Footer(){
    return (
    <div className={styles.footer_wrapper}>
        <div className={styles.footer}>
            <p>Copyright {(new Date()).getFullYear()} - Qastan</p>
        </div>
    </div>
    )
}