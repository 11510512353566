import Navigation from "../components/Navigation";
import styles from "../styles/Main.module.scss";
import question_image from "../assets/question_image.png";
import guest from "../assets/mystery_guest.png";
import cyberveiligheid_image from "../assets/cyberveiligheid_image.png";
import { useState } from "react";
import checkmark from "../assets/checkmark.svg";
import Fade from 'react-reveal/Fade';
import scroll_animation from "../assets/animations/scroll_indicator.json";
import Lottie from 'react-lottie';
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import event_image from "../assets/event_image.jpg";
import EventForm from "../components/EventForm";
import Planning from "../components/Planning";

export default function Main(){
    const navigate = useNavigate();
    const [activeService, setactiveService] = useState("1");
    const [showEventForm, setshowEventForm] = useState(false);
    const [showPlanning, setshowPlanning] = useState(false);

    const [scrollOptions, setscrollOptions] = useState({
        loop: true,
        autoplay: true,
        animationData: scroll_animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    });

    return (
        <>
        {showEventForm && 
            <EventForm onClose={()=>{setshowEventForm(false)}}/>
        }

        {showPlanning &&
            <Planning onClose={()=>{setshowPlanning(false)}}/>
        }

        <Navigation />

        <div className={styles.scroll_indicator}>
            <Lottie options={scrollOptions} speed={0.4}/>
        </div>

        <Fade left>
        <div id="home" className={`${styles.page}`} style={{marginTop:"10rem"}}>
            <div className={styles.page_content}>
                <p className={styles.subtitle}>360° BEVEILIGINGSSTRATEGIE</p>
                <h1 className={styles.title}>CYBERVEILIGHEID</h1>
                <p className={styles.paragraph}>De toegenomen digitalisering stelt bedrijven en organisaties meer dan ooit bloot aan diverse beveiligingsrisico’s en datalekken. Cyberveiligheid.net brengt partners uit verschillende disciplines bij elkaar om organisaties in een 360°-aanpak bij te staan in het uittekenen van hun cyber security.</p>
                <button onClick={()=>{navigate("events")}} className={styles.cta}>SCHRIJF JE NU IN</button>

                {/* <div className={styles.latest_event}>
                    <h2>EERSTVOLGENDE EVENEMENT</h2>
                    <div className={styles.card}> 
                        <img className={styles.image} src={event_image} alt="event"/>
                        <div className={styles.content}>
                            <div className={styles.text}>
                                <p className={styles.title}>Cyberveiligheid First Edition</p>
                                <p className={styles.description}>Welkom bij de eerste editie van Cyberveiligheid. Leer hoe je je het best kan beveiligen tegen cyber-criminaliteit.</p>
                                <p className={styles.address}>Spoorwegstraat 23C, 8530 Harelbeke</p>
                                <button onClick={()=>{setshowEventForm(true)}} className={styles.subscribe_button}>Inschrijven</button>
                                <button onClick={()=>{setshowPlanning(true)}} className={styles.planning_button}>Planning</button>
                            </div>

                            <div className={styles.date}>
                                <p className={styles.number}>19</p>
                                <p className={styles.month}>JAN</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        </Fade>

        <Fade right>
        <div className={`${styles.page}`}>
            <div className={styles.page_content}>
                <div className={styles.question_page}>
                    <img className={styles.question_image} src={question_image} alt="security"/>
                    <div className={styles.question_content}>
                        <p className={styles.question_text}>HOE WAT EN WAAR?</p>
                        <p className={styles.question_text}>WAT DOE JE ERAAN?</p>
                        <p className={styles.question_text}>PHISHING EN ONLINE VEILIGHEID</p>
                        <p className={`${styles.question_text} ${styles.blue}`}>THE HUMAN FIREWALL</p>
                    </div>
                </div>
            </div>
        </div>
        </Fade >

        <Fade left>
        <div id="events" className={`${styles.page}`}>
            <div className={styles.page_content}>
                <p className={styles.subtitle}>INFOSESSIE EN INSPIRATIE-EVENT</p>
                <h1 className={styles.title}>THE HUMAN FIREWALL</h1>
                <p className={styles.paragraph}>Qastan en zijn partners slaan in dit inspiratie-event als experten de handen in elkaar om – elk vanuit hun specifieke invalshoek – de risico’s van cybercriminaliteit toe te lichten. Tijdens dit evenement buigen we ons over een algemene basisveiligheid en hoe we onszelf en de organisatie bewapenen.</p>

                <p className={styles.paragraph}>Het inspiratie-event is gratis, maar we vragen wel om tijdig in te schrijven. Het aantal plaatsen is immers beperkt.</p>
            
                <button onClick={()=>{navigate("events")}} className={styles.cta}>BEKIJK ONZE PLANNING</button>
            </div>
        </div>
        </Fade>

        {/* <Fade right>
        <div id="speakers" className={`${styles.page}`}>
            <div className={styles.page_content}>
                <p className={styles.subtitle}>SPREKERS</p>

                <div className={styles.speaker}>
                    <div>
                        <h1 className={styles.title}>Naam Voornaam</h1>
                        <p className={styles.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                    <img className={styles.speaker_image} src={guest} alt="guest"/>
                </div>
            </div>
        </div>
        </Fade> */}

        <Fade right>
        <div id="about" className={`${styles.page}`}>
            <div className={styles.page_content}>
                <div className={styles.about}>
                    <img className={styles.about_image} src={cyberveiligheid_image} alt="conference"/>
                    <div className={styles.about_content}>
                        <p className={styles.subtitle}>OVER ONS</p>
                        <h1 className={styles.title}>Cyberveiligheid</h1>
                        <p className={styles.paragraph}>Cyberveiligheid.net brengt partners uit verschillende disciplines bij elkaar om zelfstandigen, organisaties en ondernemingen in 360°-aanpak bij te staan in het uittekenen van hun beveiligingsstrategie. Naast technische oplossingen, bieden onze experten ook het nodige juridische advies en diverse trainingen aan. Dit om een organisatiebrede cyberstrategie mogelijk te maken en elke organisatie te wapenen tegen deze groeiende vorm van criminaliteit.</p>
                    </div>
                </div>
            </div>
        </div>
        </Fade>

        <Fade left>
        <div id="services" className={`${styles.page}`}>
            <div className={styles.page_content}>
                <div className={styles.services}>
                    <div>
                        <p className={styles.subtitle}>SERVICES</p>
                        <h1 className={styles.title}>ONZE DIENSTEN</h1>
                        <p className={styles.paragraph}>Cyberveiligheid vraagt om een 360°aanpak waarbij naast technische oplossingen ook sterk juridische advies en blijvende bewustwording (onder de vorm van trainingen) noodzakelijk zijn. Enkel op deze manier is er sprake van een echt organisatiebrede cyberstrategie.</p>
                        <p className={styles.paragraph}>Een volledig waterdichte aanpak bestaat dan wel niet, toch is het duidelijk dat cyberrisico’s een blijvend aandachtspunt vormen die het best snel en accuraat aangepakt worden. Bovendien kan het ook een verlengstuk zijn van het imago van de organisatie of corporate identity.</p>
                    </div>
                    <div>
                        <div className={styles.services_buttons}>
                            <button onClick={()=>{setactiveService("1")}} className={`${styles.services_button} ${activeService === "1" && styles.services_button_active}`}>IT AUDIT</button>
                            <button onClick={()=>{setactiveService("3")}} className={`${styles.services_button} ${activeService === "3" && styles.services_button_active}`}>OPLEIDINGEN EN TRAININGEN</button>
                        </div>
                        {activeService === "1" && <div>
                            <h3>DE START VAN ELKE BEVEILIGINGSSTRATEGIE</h3>
                            <p>Onze IT audits bieden een diepgaand inzicht op de actuele situatie (zowel software, hardware als infrastructuur) in uw organisatie. Specifiek bekijken we de kwetsbare punten in jouw beveiliging en bedrijfsprocessen. Het eindresultaat is een technisch rapport met een aantal noodzakelijke aanpassingen alsook enkele aanbevelingen om uw organisatie niet alleen resistenter maar ook futureproof te maken.</p>
                            <ul className={styles.services_list}>
                                <li className={styles.services_list_item}><p>Back-ups en restore-strategie</p></li>
                                <li className={styles.services_list_item}><p>Performantie van het netwerk met oog op beveiliging en cyberveiligheid</p></li>
                                <li className={styles.services_list_item}><p>Dataveiligheid en GDPR</p></li>
                                <li className={styles.services_list_item}><p>Roadmap op basis van uw budget en perspectieven</p></li>
                            </ul>
                        </div>}
                        {activeService === "2" && <div>
                            <h3>PREVENTIE EN CRISIS MANAGEMENT</h3>
                            <p>Onze juridische begeleiding focust zich in de eerste plaats op het vermijden en inschatten van alle (strafrechtelijke) risico’s en de hieraan gekoppelde verantwoordelijkheden in ondernemingen en organisaties. Denk hierbij aan contracten, compliance, verantwoordelijkheden en aansprakelijkheid. Wanneer toch zou misgaan, staan we tevens paraat om de crisis te beheren een vanuit ons netwerk de juiste expertise aan te bieden. Als experten in het ondernemingsstrafrecht worden gefocust op volgende diensten:</p>
                            <ul className={styles.services_list}>
                                <li className={styles.services_list_item}><p>Adviesverlening (strafrechtelijke risico’s vermijden en inschatten, inclusief cassatie-advies)</p></li>
                                <li className={styles.services_list_item}><p>Legal risk management</p></li>
                                <li className={styles.services_list_item}><p>Juridische begeleiding</p></li>
                                <li className={styles.services_list_item}><p>Crisis management tijdens en na strafrechtelijke incidenten</p></li>
                                <li className={styles.services_list_item}><p>Geschillenbehandeling voor alle hoven en rechtbanken, inclusief het Hof van Cassatie</p></li>
                            </ul>
                        </div>}
                        {activeService === "3" && <div>
                            <h3>BEWUSTWORDING EN AWARENESS, WANNEER HERKENNING DE BESTE VERDEDIGING VORMT</h3>
                            <p>Opleidingen en trainingen versterken de weerbaarheid van organisaties. De sterkste verdediging (maar eveneens het grootste risico) wordt immers gevormd door de mensen binnen de eigen organisatie.</p>
                            <ul className={styles.services_list}>
                                <li className={styles.services_list_item}><p>Voor bedrijven: bouwen aan de eigen menselijke firewall (phishing)</p></li>
                                <li className={styles.services_list_item}><p>Voor organisaties en scholen</p></li>
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        </Fade>

        <Fade right>
        <div className={`${styles.page}`}>
            <div className={styles.page_content}>
                <div className={styles.about_content}>
                    <p className={styles.subtitle}>TRAININGEN</p>
                    <h1 className={styles.title}>BEDRIJFSTRAININGEN</h1>
                    <p className={styles.paragraph}>Onze trainingen richten zich dus in de eerste plaats op het weerbaar en bewust maken van de risico’s van phishing bij werknemers en organisaties. In meer dan 90% van de gevallen van alle cyberaanvallen en datalekken, liggen menselijke fouten immers aan de grondslag en starten deze met frauduleuze mails in de mailbox van een werknemer. Deze zogenaamde phishingtechnieken en mails worden ook steeds beter, gerichter opgesteld en maken in vele gevallen gebruik van persoonlijke gegevens of semi-persoonlijke gegevens (denk hierbij aan bekende merken die je volgt of lidmaatschappen). Vaak is het onwetendheid of onoplettendheid die aan de basis ligt om toch de mails, de bijlages of de link aan te klikken.</p>
                    <p className={styles.paragraph}>Alle trainingen worden gepersonaliseerd op basis van het niveau en het gedrag van elke gebruiker, ongeacht de grootte van de organisatie.</p>
                </div>
            </div>
        </div>
        </Fade>

        <Fade left>
        <div id="price" className={`${styles.page}`}>
            <div className={styles.page_content}>
                <div className={styles.training}>
                    <div>
                        <p className={styles.subtitle}>PRIJZEN</p>
                        <h1 className={styles.title}>BEDRIJFSTRAININGEN</h1>
                        <p className={styles.paragraph}>Hoe zien onze opleidingen er uit?</p>
                    </div>

                    <div className={`${styles.cards}`}>
                        <div className={`${styles.card}`}>
                            <div>
                                <p className={`${styles.title}`}>Awareness training</p>
                                <div className={`${styles.mini_cards}`}>
                                    <div>
                                        <p className={`${styles.price_bold}`}>€ 485</p>
                                        <p className={`${styles.price_description}`}>Tot 5 gebruikers</p>
                                        <p className={`${styles.price_subinfo}`}>Of 8 EUR per gebruiker per maand</p>
                                    </div>
                                    <div>
                                        <p className={`${styles.price_bold}`}>€ 485</p>
                                        <p className={`${styles.price_description}`}>Tot 15 gebruikers</p>
                                        <p className={`${styles.price_subinfo}`}>Of 4.15 EUR per gebruiker per maand</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.card}`}>
                            <div>
                                <p className={`${styles.title}`}>Awareness training+</p>
                                <div className={`${styles.mini_cards}`}>
                                    <div>
                                        <p className={`${styles.price_bold}`}>€ 985</p>
                                        <p className={`${styles.price_description}`}>Tot 5 gebruikers</p>
                                        <p className={`${styles.price_subinfo}`}>Of 16.40 EUR per gebruiker per maand</p>
                                    </div>
                                    <div>
                                        <p className={`${styles.price_bold}`}>€ 1150</p>
                                        <p className={`${styles.price_description}`}>Tot 5 gebruikers</p>
                                        <p className={`${styles.price_subinfo}`}>Of 6.40 EUR per gebruiker per maand</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.table_container}>
                        <table className={styles.table}>
                            <tbody>
                                <tr className={styles.table_top}>
                                    <th></th>
                                    <th>Awareness training</th>
                                    <th>Awareness training+</th>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Intake-moment om de focuspunten vast te leggen</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Opleidingsmoment op maat van de organisatie</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Geautomatiseerde phishingsimulaties</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>2 tussentijdse evaluatiemomenten</td>
                                    <td className={styles.checkmark}></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Geautomatiseerde phishingsimulaties</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Maandelijkse rapportage</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Mogelijkheid om verdachte mails te signaliseren in de mailbox voordat ze schade aanrichten</td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                                <tr className={styles.table_row}>
                                    <td>Analyse van verdachte mails en terugkoppeling op vals positieve of frauduleuze mails van</td>
                                    <td className={styles.checkmark}></td>
                                    <td className={styles.checkmark}><img src={checkmark} alt="checkmark"/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </Fade>

        <Footer/>
        </>
    )
}