import styles from "../styles/Events.module.scss"

export default function EventForm({onClose}){
    return (
        <div className={styles.popup}>
            <div className={styles.ct}>
                <button onClick={()=>{onClose()}} className={styles.close_button}>Sluiten</button>

                <script type="text/javascript" src="https://campaigns.zoho.eu/js/zc.iframe.js"></script>
                <iframe style={{height:"100vh"}} frameBorder="0" id="iframewin" width="100%" height="100vh" src="https://qast-zcmp.maillist-manage.eu/ua/Optin?od=12ba7e8c00b6&zx=14ad2ad2ab&sD=11bd6db79c079bd1"></iframe>
            </div>
        </div>
    )
}