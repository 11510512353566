import logo from "../assets/logo.svg";
import styles from "../styles/Events.module.scss";
import { useNavigate } from "react-router-dom";
import event_image from "../assets/event_image.jpg";
import EventForm from "../components/EventForm";
import { useState } from "react";
import Planning from "../components/Planning";

export default function Events(){
    const navigate = useNavigate();

    const [showEventForm, setshowEventForm] = useState(false);
    const [showPlanning, setshowPlanning] = useState(false);

    return (
        <>
        {showEventForm && 
            <EventForm onClose={()=>{setshowEventForm(false)}}/>
        }

        {showPlanning &&
            <Planning onClose={()=>{setshowPlanning(false)}}/>
        }

        <div className={styles.header_wrapper}>
            <div className={styles.header}>
                <img src={logo} alt="logo"/>
                <button onClick={()=>{navigate("/")}} className={styles.back_button}>Terug naar de website</button>
            </div>
        </div>

        <div className={styles.events_wrapper}>
            <div className={`${styles.ball} ${styles.ball_1}`}></div>
            <div className={`${styles.ball} ${styles.ball_2}`}></div>
            <div className={`${styles.ball} ${styles.ball_3}`}></div>

            <div className={styles.events}>
                <p className={styles.title}>Evenementen</p>
                <div className={styles.events_container}>
                    <div className={styles.event_card}>
                        <div className={styles.event_image}>
                            <div className={styles.mask_left}></div>
                                <img src={event_image} alt="event"/>
                            <div className={styles.mask_right}></div>
                        </div>
                        <div className={styles.event_content}>
                            <div>
                                <p className={styles.event_title}>Coming soon!</p>
                                <p className={styles.event_description}>Binnenkort kan u zich inschrijven voor de eerste editie van het Cyberveiligheid event. Houd deze pagina in de gaten!</p>
                                {/* <p className={styles.event_loc}>Spoorwegstraat 23C, 8530 Harelbeke</p> */}
                                <div className={styles.event_actions}>
                                    {/* <button onClick={()=>{setshowEventForm(true)}}>Inschrijven</button>
                                    <button className={styles.secondary_button} onClick={()=>{setshowPlanning(true)}}>Planning</button> */}
                                </div>
                            </div>
                            <div className={styles.event_date}>
                                {/* <p className={styles.event_dt_nmbr}>19</p>
                                <p className={styles.event_dt_mnth}>jan</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}