import { useState } from "react";
import styles from "../styles/Planning.module.scss";

export default function Planning({onClose}){
    const [animation, setanimation] = useState("");

    const closeDetail = (target) => {
        console.log(target)
        if(target.id.toString() === "bg"){
            setanimation("out");

            setTimeout(function(){onClose()}, 600)
        }
    }

    return (
        <div className={styles.planning_bg} id="bg" onClick={(e)=>{closeDetail(e.target)}}>
            <div className={`${animation === "out" && styles.planning_out} ${styles.planning_ct}`}>
                <button id="bg" onClick={(e)=>{closeDetail(e.target)}} className={styles.close_button}><svg id="bg" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline id="bg" points="9 18 15 12 9 6"></polyline></svg></button>

                <h2>Cyberveiligheid First Edition</h2>
                <p>Ons eerste event en hier komt nog wat tekst! Tot dan!</p>

                <h3 style={{marginTop:"2rem"}}>Het programma</h3>
                <div className={styles.program}>
                    <div className={styles.cell}>
                        <p>15h00</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Ontvangst met koffie en gebak</p>
                    </div>
                    <div className={styles.c1_r6}>
                        <p>15h30</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Introductie</p>
                        <p>Verwelkoming door Qastan</p>
                        <p>Voorstelling van Cyberveiligheid.net</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Fortinet spreker</p>
                        <p>Hoe automatisatie en integratie bijdraagt aan een veiligere omgeving.</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Eerste hulp bij Phishingaanvallen</p>
                        <p>Hoe herken ik phishing e-mails?</p>
                        <p>Hoe kan ik mijn omgeving hiertegen beschermen?</p>
                    </div>
                    <div className={styles.cell}>
                        <p>ESET spreker</p>
                        <p>Meer uitleg over huidige cyberdreigingen en hoe u hier zich zo goed mogelijk tegen kan wapenen.</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Slot</p>
                    </div>
                    <div className={styles.cell}>
                        <p>17h30 - 19h00</p>
                    </div>
                    <div className={styles.cell}>
                        <p>Netwerkmoment met een drankje</p>
                    </div>
                </div>
            </div>
        </div>
    )
}