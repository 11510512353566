import { Routes, Route } from 'react-router-dom';
import './App.css';
import Events from './pages/Events';
import Main from './pages/Main';

function App() {
  return (
    <>
    <Routes>
      <Route path="*" element={<Main />}/>
      <Route path="events" element={<Events />}/>
    </Routes>
    </>
  );
}

export default App;
