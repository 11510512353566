import styles from "../styles/Navigation.module.scss";
import logo from "../assets/logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Navigation(){
    const navigate = useNavigate();
    const [showNav, setshowNav] = useState(false);

    return (
    <>
    <div className={styles.navigation_wrapper}>
        <div className={styles.navigation}>
            <img src={logo} alt="logo"/>
            <div className={styles.header_actions}>
                <button onClick={()=>{navigate("events")}} className={styles.discover}>Discover our events</button>
                <button onClick={()=>{setshowNav(!showNav)}} className={`${styles.nav_button} ${showNav ? styles.show_navbutton : ''}`}>
                    <div className={styles.l_one}></div>
                    <div className={styles.l_two}></div>
                    <div className={styles.l_three}></div>
                </button>
            </div>
        </div>
    </div>

    <div className={`${styles.navbar} ${showNav ? styles.show_navbar : styles.hide_navbar}`}>
        <a onClick={()=>{setshowNav(!showNav)}} className={styles.nav_item}>
            <button onClick={()=>{setshowNav(false)}} className={`${styles.nav_button} ${styles.show_navbutton}`}>
                <div className={`${styles.l_white} ${styles.l_one}`}></div>
                <div className={`${styles.l_white} ${styles.l_two}`}></div>
                <div className={`${styles.l_white} ${styles.l_three}`}></div>
            </button>
        </a>
        
        <a onClick={()=>{setshowNav(!showNav)}} href="#home" className={styles.nav_item}>Home</a>
        <a onClick={()=>{setshowNav(!showNav)}} href="#events" className={styles.nav_item}>Events</a>
        <a onClick={()=>{setshowNav(!showNav)}} href="#speakers" className={styles.nav_item}>Sprekers</a>
        <a onClick={()=>{setshowNav(!showNav)}} href="#about" className={styles.nav_item}>Over ons</a>
        <a onClick={()=>{setshowNav(!showNav)}} href="#services" className={styles.nav_item}>Diensten</a>
        <a onClick={()=>{setshowNav(!showNav)}} href="#price" className={styles.nav_item}>Prijzen</a>
    </div>
    </>
    )
}